export function stickyNavigation() {

	var didScroll = false;
	var viewportHeight =  window.innerHeight;

	var currentScroll;
	var previousScroll;
	var $mainNavigation = document.getElementById('drilldown');


	// Let's add the eventlistner.
	if(window.addEventListener){
        window.addEventListener('scroll', onScrollEventHandler, false);
	} else if (el.attachEvent){
        window.attachEvent('onscroll', onScrollEventHandler);
	}

	// Let's make
    function onScrollEventHandler(ev)
    {
        didScroll = true;
    }



    setInterval(function(){
		if( didScroll ){

			updateNavigationVisibility();
			didScroll = false;
		}
	}, 50);


	function updateNavigationVisibility()
	{
		//
		currentScroll = window.pageYOffset;
		document.body.classList.add('is-active');

		// Leave the controll to the body.


		if (currentScroll < previousScroll || currentScroll < 116 ){
			console.log('show');
			showNav();
	    } else {
	    	console.log('hide');
	        hideNav();
		}

		previousScroll = currentScroll;
	}


	function showNav(){
		document.body.classList.add('is-active');
	}
	function hideNav(){
		document.body.classList.remove('is-active');
	}
	/*


	function updateNavigationVisibility()
	{
		//
		currentScroll = $(window).scrollTop();
		$mainNavigation.addClass('is-sticky');

		if (currentScroll < previousScroll || currentScroll < $(window).outerHeight() / 3 ){
			showNav();
	    } else {
	        hideNav();
		}

		previousScroll = currentScroll;
	}

	function showNav(){
		$mainNavigation.addClass('is-active');
	}
	function hideNav(){
		$mainNavigation.removeClass('is-active');
	}
	*/
}