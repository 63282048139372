// Let's use this navigations sticky
import { stickyNavigation } from './stickyNavigation';
stickyNavigation();

// Lets use vue
var Vue = require('vue');
var menuJson = document.getElementById('menu');
var navigation = new Vue({
	el: '#drilldown',

	data: {
		menu: JSON.parse(menuJson.text),
		open: false,
		childOpen: false,
		searchOpen: false,
		search: '',
	},

	methods: {

		openChild: function( item )
		{
			if( ! item.children || item.children.length <= 0)
			{
				return;
			}

			if(event){
				event.preventDefault();
			}

			// Ok so we have children, let's find out what to show.
			item.showChildren = true;
			this.childOpen = true;
			return;
		},

		closeChild: function (item)
		{
			item.showChildren = false;
			this.childOpen = false;
		},

		openMenu: function()
		{
			this.open = true;
		},

		closeMenu: function()
		{
			this.open = false;
		},

		toggleSearch: function()
		{
			if( this.searchOpen )
			{
				this.searchOpen = false;
				return;
			}

			this.searchOpen = true;
		},

		onSubmit: function(){
			window.location.href = SITE_URL + '?s=' + this.search;
		},


		autoOpen: function(){

			for(var i in this.menu ){
				var item = this.menu[i];
				var parrentIndex = i;

				for(var n in item.children){
					var child = item.children[n];

					if( child.data.post_id == POST_ID )
					{
						console.log(child.data.title);
						console.log(child.data.ID);

						this.openChild(item);
					}
				}

			}

		}
	},

	created: function(){

		this.autoOpen();
	}

});